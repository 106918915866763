import { isPlatformBrowser } from '@angular/common';
import { Inject, InjectionToken, PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';

export const IMAGE_URL_CONFIG = new InjectionToken<ImageUrlConfig>('IMAGE_URL_CONFIG_PARAMS');

export interface ImageUrlConfig {
    baseUrl: string;
    adoptToDevicePixelRation?: boolean;
}

export interface UrlConfigOptions {
    width?: number;
    height?: number;
}

@Pipe({
    standalone: true,
    name: 'resolveImageUrl'
})
export class ResolveImageUrlPipe implements PipeTransform {
    private readonly platformId = inject(PLATFORM_ID);
    constructor(@Inject(IMAGE_URL_CONFIG) private readonly config: ImageUrlConfig) {}

    transform(name: string | undefined | null, config: UrlConfigOptions = { width: 0, height: 0 }): string {
        let scale = 1;
        if (isPlatformBrowser(this.platformId)) {
            scale = this.config.adoptToDevicePixelRation ? window.devicePixelRatio : 1;
        }

        if (config.width && config.height) {
            return `${this.config.baseUrl}/image/w/${config.width * scale}/h/${config.height * scale}/${name}`;
        } else if (config.width && !config.height) {
            return `${this.config.baseUrl}/image/w/${config.width * scale}/h/0/${name}`;
        } else if (!config.width && config.height) {
            return `${this.config.baseUrl}/image/w/0/h/${config.height * scale}/${name}`;
        } else {
            return `${this.config.baseUrl}/image/${name}`;
        }
    }
}
